import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import StatesContextComponent from './Contexts/statesContext';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
//   <React.StrictMode>
        <StatesContextComponent>
              <App />
        </StatesContextComponent>

//   </React.StrictMode>
);
