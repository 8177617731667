import ListVideosCard from "../../Components/ListVideosCard/ListVideosCard"
import Watch from "../Watch/Watch";

const PlayList = ()=>{
    return (
        <Watch >
            <ListVideosCard />
        </Watch>
    )
};

export default PlayList;